// REACT:
import React from 'react'

// COMPONENTS:
import Weather from '../Weather/Weather'

// RESULTS:
export default function Results() {

    return (

        <div>
            <Weather/> 
        </div>

    )
}